import React, { Component } from "react";
import styled from "styled-components";

const Footer = styled.div`
  height: 120px;
  background-color: ${props => (props.hide ? "#fff" : "#0075c1")};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 160px;
  @media screen and (max-width: 1200px) {
    padding: 0 65px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }

  & > button {
    display: ${props => (props.hide ? "none" : "block")};
    width: 160px;
    height: 40px;
    border: 0;
    font-size: 14px;
    color: #0075c1;
    background-color: #fff;
    cursor: pointer;
    outline: 0;

    &:disabled {
      background-color: #a9a9a9;
      color: #fff;
      opacity: 0.9;
      cursor: not-allowed;
    }
  }
`;

class CartFooterSection extends Component {
  render() {
    const { text, onClick, hide, disabled } = this.props;

    return (
      <Footer hide={hide}>
        <button type="button" onClick={onClick} disabled={disabled}>
          {text}
        </button>
      </Footer>
    );
  }
}

export default CartFooterSection;
