import React from "react";
import styled from "styled-components";
import Constants from "../Domain/constants";
import * as Widget from "./Widget";

class CVSSelector extends React.Component {
  render() {
    const { storeId, storeName } = this.props;

    return (
      <div className="store-wrapper">
        <div>
          <p>門市店號：{storeId || "--"}</p>
          <p>門市名稱：{storeName || "--"}</p>
        </div>
        <button type="button">選取門市</button>
      </div>
    );
    // let { extraCss, store_id, store_name, onSelect } = this.props;
    //
    // return (
    //   <CVSSelector.Wrapper extraCss={extraCss}>
    //     <form
    //       id="_cvs_chooser_form_"
    //       method="post"
    //       action="https://logistics-stage.ecpay.com.tw/Express/map"
    //     >
    //       <input type="hidden" name="MerchantID" value="2000132" />
    //       <input type="hidden" name="LogisticsType" value="CVS" />
    //       <input type="hidden" name="LogisticsSubType" value="UNIMART" />
    //       <input type="hidden" name="IsCollection" value="N" />
    //       <input
    //         type="hidden"
    //         name="ServerReplyURL"
    //         value={`${Constants.apiUrl}/api/ecpay_cvs_selector/result/`}
    //       />
    //     </form>
    //     <Widget.Row align="center">
    //       <Widget.Button
    //         label={store_id ? '重選門市' : '選取門市'}
    //         onClick={() => {
    //           onSelect &&
    //             onSelect(document.getElementById('_cvs_chooser_form_'));
    //         }}
    //       />
    //       <div style={{ marginLeft: 10 }}>
    //         <div>{`門市店號: ${store_id || '---'}`}</div>
    //         <div>{`門市名稱: ${store_name || '---'}`}</div>
    //       </div>
    //     </Widget.Row>
    //   </CVSSelector.Wrapper>
    // );
  }
}

export default CVSSelector;
