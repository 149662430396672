import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const BarWrapper = styled.div`
  flex: 1;

  & > div {
    height: 10px;
    margin-bottom: 20px;
    background-color: ${props => (props.isActived ? "#0075c1" : "#cbcbcb")};
  }

  & > p {
    font-size: 14px;
    color: ${props => (props.isActived ? "#0075c1" : "#cbcbcb")};
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`;

class CartStatusBar extends Component {
  render() {
    const { step } = this.props;

    return (
      <Wrapper>
        <BarWrapper isActived={step >= 1}>
          <div />
          <p>01.填寫寄件資訊</p>
        </BarWrapper>
        <BarWrapper isActived={step >= 2}>
          <div />
          <p>02.付款</p>
        </BarWrapper>
        <BarWrapper isActived={step >= 3}>
          <div />
          <p>03.完成訂單</p>
        </BarWrapper>
      </Wrapper>
    );
  }
}

export default CartStatusBar;
