import React, { Component, Fragment } from "react";
import styled from "styled-components";
import * as Icon from "../Components/Icon";
import * as Widget from "../Components/Widget";

class CartSingleSelectorField extends Component {
  render() {
    const { options, value, onChange } = this.props;

    return (
      <Fragment>
        {options.map(option => (
          <Widget.CheckboxField
            key={option.value}
            extraCss={option.extraCss || ""}
          >
            <div className="box-wrapper" onClick={() => onChange(option.value)}>
              {value === option.value ? (
                <Icon.RadioButtonChecked color="#3f8dcf" size={20} />
              ) : (
                <Icon.RadioButtonUnchecked color="#979797" size={20} />
              )}
            </div>
            <p className="label-text">{option.label}</p>
          </Widget.CheckboxField>
        ))}
      </Fragment>
    );
  }
}

export default CartSingleSelectorField;
