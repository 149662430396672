import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Icon from "./Icon";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import CartItem from "./CartItem";
import * as Widget from "./Widget";

const Wrapper = styled.div`
  border: 1px solid #dadada;
  height: 100%;
  padding: 30px;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }

  & > .open-button {
    display: none;
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > p {
        font-size: 16px;
        color: #575757;
      }
    }
  }

  & > .list-wrapper {
    border-bottom: 1px solid #dadada;
    @media screen and (max-width: 1200px) {
      padding-top: 30px;
      margin-top: 20px;
      border-top: 1px solid #dadada;
    }
  }

  & > .subtotal-wrapper {
    border-bottom: 1px solid #dadada;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;

      & > p {
        font-size: 16px;
        color: #575757;
      }
    }
  }

  & > .total-wrapper {
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0 16px 0;

      & > p {
        font-size: 16px;
        color: #575757;
      }
    }
  }
`;

class CartSection extends Component {
  state = {
    isOpened: true
  };

  componentDidMount() {
    if (window.innerWidth <= 1200) {
      this.setState({ isOpened: false });
    }
  }

  render() {
    let { isOpened } = this.state;
    let { editMode, cartData } = this.props;

    if (!cartData) {
      return (
        <Widget.Center>
          <Widget.Spinner />
        </Widget.Center>
      );
    }
    return (
      <Wrapper>
        <div
          className="open-button"
          onClick={() => this.setState({ isOpened: !isOpened })}
        >
          <p>購物清單</p>
          {isOpened ? (
            <Icon.ArrowUp color="#575757" size={18} />
          ) : (
            <Icon.ArrowDown color="#575757" size={18} />
          )}
        </div>
        {isOpened && (
          <Fragment>
            <div className="list-wrapper">
              {cartData.items.map((item, idx) => (
                <CartItem
                  key={idx}
                  data={item}
                  itemIndex={idx}
                  editMode={editMode}
                />
              ))}
            </div>
            <div className="subtotal-wrapper">
              <div>
                <p>商品小計</p>
                <p>{cartData.calculation.amount}元</p>
              </div>
            </div>
            <div className="total-wrapper">
              <div>
                <p>總計</p>
                <p>{cartData.calculation.amount}元</p>
              </div>
            </div>
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    cartData: Selectors.getCartData(state)
  }),
  ActionCreator
)(CartSection);
