import React, { Fragment } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
`;

const InfoSection = styled.div`
  width: 621px;
  margin-right: 100px;
  @media screen and (max-width: 1200px) {
    flex: 1;
    margin-right: 0;
  }
  @media screen and (max-width: 767px) {
    width: 335px;
  }
  @media screen and (max-width: 374px) {
    width: 300px;
  }
`;

const CartSection = styled.div`
  width: 400px;

  @media screen and (max-width: 1200px) {
    width: 620px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    width: 335px;
  }
  @media screen and (max-width: 374px) {
    width: 300px;
  }
`;

class CheckoutWrapper extends React.Component {
  render() {
    const { infoSection, cartSection } = this.props;

    return (
      <Wrapper>
        <InfoSection>{infoSection}</InfoSection>
        <CartSection>{cartSection}</CartSection>
      </Wrapper>
    );
  }
}

export default CheckoutWrapper;
