import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CartTextInputField from "../../Fields/CartTextInputField";
import CartSingleSelectorField from "../../Fields/CartSingleSelectorField";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../../Components/Icon";
import * as Widget from "../../Components/Widget";
import CartStatusBar from "../../Components/CartStatusBar";
import CheckoutWrapper from "../../Components/CheckoutWrapper";
import CartSection from "../../Components/CartSection";
import CartFooterSection from "../../Components/CartFooterSection";
import CVSSelector from "../../Components/CVSSelector";

const PAYMENT = {
  CREDIT_CARD_ONLINE: "CREDIT_CARD_ONLINE",
  CASH_ON_DELIVERY: "CASH_ON_DELIVERY"
};

const PLACE_OF_DELIVERY = {
  SEVEN_ELEVEN: "SEVEN_ELEVEN",
  AIRPORT: "AIRPORT"
};

const AIRPORT_LOCATIONS = [
  {
    label: "台北松山-統振櫃檯",
    value: "airport-TSA",
    extraCss: ""
  },
  {
    label: "桃園一航-多圓發櫃檯",
    value: "airport-TPE-T1",
    extraCss: ""
  },
  {
    label: "桃園二航-多圓發櫃檯",
    value: "airport-TPE-T2",
    extraCss: ""
  },
  {
    label: "高雄小港-統振櫃檯",
    value: "airport-KHH",
    extraCss: ""
  }
];

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
`;

const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  & > .title {
    margin-top: 52px;
    font-size: 16px;
    color: #575757;

    & > span {
      font-size: 14px;
      color: #f92b2b;
      margin-left: 10px;
    }
  }

  & > .hint {
    font-size: 14px;
    color: #a4a4a4;
  }

  & > .checkbox-wrapper {
    display: flex;
    flex-direction: row;
  }

  & > .store-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-right: 250px;
    @media screen and (max-width: 767px) {
      padding-right: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    & > div {
      & > p {
        font-size: 14px;
        color: #575757;
        margin-top: 20px;
      }
    }

    & > button {
      width: 114px;
      height: 40px;
      font-size: 14px;
      color: #0075c1;
      background-color: #fff;
      border: 1px solid #0075c1;
      outline: 0;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
`;

const Footer = styled.div`
  height: 120px;
  background-color: #0075c1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 160px;

  & > button {
    width: 160px;
    height: 40px;
    border: 0;
    font-size: 14px;
    color: #0075c1;
    background-color: #fff;
    cursor: pointer;
  }
`;

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { cartData, appActions } = this.props;

    if (cartData && cartData.items.length === 0) {
      return <PageWrapper>購物車為空</PageWrapper>;
    }

    return (
      <PageWrapper>
        <CheckoutWrapper
          infoSection={this._renderInfoSection()}
          cartSection={<CartSection editMode />}
        />
        <CartFooterSection
          text="下一步"
          onClick={this._submit}
          disabled={!this._isAllowNextStep()}
        />
      </PageWrapper>
    );
  }

  _submit = () => {
    const { navActions } = this.props;

    if (this._isAllowNextStep()) {
      navActions.push("/checkout");
    }
  };

  _isAllowNextStep = () => {
    return true;
  };

  _renderInfoSection = () => {
    return (
      <InfoWrapper>
        <CartStatusBar step={1} />
        <Fragment>
          <p className="title">
            收件人資訊 <span>*為必填欄位</span>
          </p>
          <CartTextInputField
            label="姓名"
            isRequired
            value={"假的"}
            onChange={value => 0}
          />
          <p className="hint">
            ◎提醒您，我們將會於取貨後10個工作天內寄送電子發票至您所填寫的E-Mail，請務必確認此電子信箱可以正常收發信件。
          </p>
        </Fragment>

        <Fragment>
          <p className="title">選擇付款方式</p>
          <CartSingleSelectorField
            value={PAYMENT.CREDIT_CARD_ONLINE}
            onChange={value => this.setState({ payment: value })}
            options={[
              {
                label: "線上刷卡",
                value: PAYMENT.CREDIT_CARD_ONLINE,
                extraCss: ""
              },
              {
                label: "超商貨到付款",
                value: PAYMENT.CASH_ON_DELIVERY,
                extraCss: ""
              }
            ]}
          />
          <p className="hint" />
        </Fragment>

        <Fragment>
          <p className="title">選擇取貨地點</p>
          <div className="checkbox-wrapper" />
        </Fragment>
      </InfoWrapper>
    );
  };
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.getCartData(state)
    }),
    ActionCreator
  )(CartPage)
);
